<template>
    <component-slide-in :class="{ active }" @close="close">

        <template v-if="!loading.USER_GET_PROFILE && !loading.USER_UPDATE_PROFILE">
            <div class="d-flex align-items-center mb-3">
                <component-avatar>
                    {{ profile.firstName.substr(0, 1).toUpperCase() + profile.lastName.substr(0, 1).toUpperCase() }}
                </component-avatar>
                <component-slide-in-title :editable="false"
                                          :subtitle="profile.username"
                                          :title="profile.firstName + ' ' + profile.lastName"
                                          :uuid="profile.id"
                                          class="ms-3 flex-grow-1"/>
            </div>

            <nav class="nav nav-tabs mb-3">
                <button id="nav-general-tab"
                        aria-controls="nav-general"
                        aria-selected="false"
                        class="nav-link active"
                        data-bs-target="#nav-general"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $t('general') }}
                </button>
                <button id="nav-contact-information-tab"
                        aria-controls="nav-contact-information"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-contact-information"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $t('contact-information') }}
                </button>
                <button id="nav-authentication-methods-tab"
                        aria-controls="nav-authentication-methods"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-authentication-methods"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $t('authentication-methods') }}
                </button>
                <button id="nav-references-tab"
                        aria-controls="nav-references"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-references"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('reference', 2) }}
                </button>
                <button id="nav-comments-tab"
                        aria-controls="nav-comments"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-comments"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('comment', 2) }}
                </button>
                <button id="nav-activity-tab"
                        aria-controls="nav-activity"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-activity"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('activity', 1) }}
                </button>
            </nav>

            <div id="nav-tabContent" class="tab-content">
                <div id="nav-general" aria-labelledby="nav-general-tab" class="tab-pane fade show active"
                     role="tabpanel">
                    <form @submit.prevent="onUpdateUser">
                        <div class="row">
                            <div class="col-6">
                                <p><strong>{{ $t('personal-information') }}</strong></p>

                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="profile-salutation">{{ $tc('salutation', 1) }}</label>
                                    <select id="profile-salutation"
                                            v-model="salutation"
                                            :aria-label="$tc('salutation', 1)"
                                            :class="{'is-invalid': this.$v.salutation.$invalid}"
                                            :disabled="!edit.flag"
                                            aria-describedby="profile-salutation"
                                            class="form-select form-select-sm"
                                            type="text">
                                        <option disabled value="">{{ $t('actions.please-select') }}</option>
                                        <option value="MR">{{ $t('salutations.MR') }}</option>
                                        <option value="MS">{{ $t('salutations.MS') }}</option>
                                    </select>
                                    <div v-if="!this.$v.salutation.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.salutation') }}
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="profile-first-name">{{ $tc('first-name', 1) }}</label>
                                    <input id="profile-first-name"
                                           v-model="firstName"
                                           :aria-label="$tc('first-name', 1)"
                                           :class="{'is-invalid': this.$v.firstName.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="profile-first-name"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.firstName.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.first-name') }}
                                    </div>
                                    <div v-if="!this.$v.firstName.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.first-name', {count: $v.firstName.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="profile-last-name">{{ $tc('last-name', 1) }}</label>
                                    <input id="profile-last-name"
                                           v-model="lastName"
                                           :aria-label="$tc('last-name', 1)"
                                           :class="{'is-invalid': this.$v.lastName.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="profile-last-name"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.lastName.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.last-name') }}
                                    </div>
                                    <div v-if="!this.$v.lastName.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.last-name', {count: $v.lastName.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label small"
                                           for="profile-birthday">{{ $tc('birthday', 1) }}</label>
                                    <input id="profile-birthday"
                                           v-model="birthday"
                                           :aria-label="$tc('birthday', 1)"
                                           :disabled="!edit.flag"
                                           aria-describedby="profile-birthday"
                                           class="form-control form-control-sm"
                                           type="date">
                                </div>

                                <p>
                                    <button v-if="!edit.flag"
                                            class="btn btn-sm btn-outline-primary me-2"
                                            type="button"
                                            @click="onClickEdit">
                                        {{ $t('actions.edit') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            :disabled="this.$v.$invalid"
                                            class="btn btn-sm btn-outline-success me-2">
                                        {{ $t('actions.update') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            class="btn btn-sm btn-outline-danger"
                                            type="button"
                                            @click="edit.flag = false">
                                        {{ $t('actions.cancel') }}
                                    </button>
                                </p>
                            </div>

                            <div class="col-6">
                                <p><strong>{{ $tc('company', 1) }}</strong></p>

                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="profile-company">{{ $tc('company', 1) }}</label>
                                    <input id="profile-company"
                                           v-model="company"
                                           :aria-label="$tc('company', 1)"
                                           :class="{'is-invalid': this.$v.company.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="profile-company"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.company.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.company', {count: $v.company.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="profile-vat-id">{{ $tc('vat-id', 1) }}</label>
                                    <input id="profile-vat-id"
                                           v-model="vatId"
                                           :aria-label="$tc('vat-id', 1)"
                                           :disabled="!edit.flag"
                                           aria-describedby="profile-vat-id"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.vatId.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.vat-id', {count: $v.vatId.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="nav-contact-information" aria-labelledby="nav-contact-information-tab" class="tab-pane fade"
                     role="tabpanel">
                    <form @submit.prevent="onUpdateUser">
                        <div class="row">
                            <div class="col-6">
                                <p><strong>{{ $tc('physical-address', 1) }}</strong></p>

                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="profile-street">{{ $tc('street', 1) }}</label>
                                    <input id="profile-street"
                                           v-model="street"
                                           :aria-label="$tc('street', 1)"
                                           :class="{'is-invalid': this.$v.street.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="profile-street"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.street.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.street') }}
                                    </div>
                                    <div v-if="!this.$v.street.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.street', {count: $v.street.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="profile-postcode">{{ $tc('postcode', 1) }}</label>
                                    <input id="profile-postcode"
                                           v-model="postcode"
                                           :aria-label="$tc('postcode', 1)"
                                           :class="{'is-invalid': this.$v.postcode.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="profile-postcode"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.postcode.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.postcode') }}
                                    </div>
                                    <div v-if="!this.$v.postcode.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.postcode', {count: $v.postcode.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="profile-city">{{ $tc('city', 1) }}</label>
                                    <input id="profile-city"
                                           v-model="city"
                                           :aria-label="$tc('city', 1)"
                                           :class="{'is-invalid': this.$v.city.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="profile-city"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.city.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.city') }}
                                    </div>
                                    <div v-if="!this.$v.city.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.city', {count: $v.city.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="profile-state">{{ $tc('state', 1) }}</label>
                                    <input id="profile-state"
                                           v-model="state"
                                           :aria-label="$tc('state', 1)"
                                           :class="{'is-invalid': this.$v.state.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="profile-state"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.state.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.state', {count: $v.state.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label small"
                                           for="profile-country">{{ $tc('country', 1) }}</label>
                                    <select id="profile-country"
                                            v-model="country"
                                            :aria-label="$tc('country', 1)"
                                            :class="{'is-invalid': this.$v.country.$invalid}"
                                            :disabled="!edit.flag"
                                            aria-describedby="profile-country"
                                            class="form-select form-select-sm">
                                        <option disabled value="">{{ $t('actions.please-select') }}</option>
                                        <option v-for="(country, iso2) in countries" :key="'country-' + iso2"
                                                :value="iso2">
                                            {{ country.name }}
                                        </option>
                                    </select>
                                    <div v-if="!this.$v.country.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.country') }}
                                    </div>
                                </div>

                                <p>
                                    <button v-if="!edit.flag"
                                            class="btn btn-sm btn-outline-primary me-2"
                                            type="button"
                                            @click="onClickEdit">
                                        {{ $t('actions.edit') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            :disabled="this.$v.$invalid"
                                            class="btn btn-sm btn-outline-success me-2">
                                        {{ $t('actions.update') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            class="btn btn-sm btn-outline-danger"
                                            type="button"
                                            @click="edit.flag = false">
                                        {{ $t('actions.cancel') }}
                                    </button>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="nav-authentication-methods" aria-labelledby="nav-authentication-methods-tab" class="tab-pane fade"
                     role="tabpanel">
                    <div class="row">
                        <div class="col-6">
                            <p><strong>{{ $t('basic-authentication') }}</strong></p>
                            <div class="mb-3">
                                <label class="form-label">{{ $t('access-key') }}</label>
                                <input v-model="accessKey"
                                       class="form-control form-control-sm"
                                       disabled="disabled"
                                       type="text">
                            </div>
                            <div>
                                <label class="form-label">{{ $t('access-secret') }}</label>
                                <div class="input-group">
                                    <input v-model="accessSecret"
                                           class="form-control form-control-sm"
                                           disabled="disabled"
                                           type="text">
                                    <button v-if="!showAccessKey"
                                            :aria-label="$t('actions.show')"
                                            class="btn btn-outline-secondary btn-sm"
                                            type="button"
                                            @click="showAccessKey = true">
                                        <font-awesome-icon class="me-1" icon="eye"/>
                                    </button>
                                    <button v-if="showAccessKey"
                                            :aria-label="$t('actions.hide')"
                                            class="btn btn-outline-secondary btn-sm"
                                            type="button"
                                            @click="showAccessKey = false">
                                        <font-awesome-icon class="me-1" icon="eye-slash"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div id="nav-references" aria-labelledby="nav-references-tab" class="tab-pane fade" role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-references-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-comments" aria-labelledby="nav-comments-tab" class="tab-pane fade" role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-comments-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-activity" aria-labelledby="nav-activity-tab" class="tab-pane fade" role="tabpanel"></div>
            </div>

        </template>

        <template v-else>
            <div class="d-flex align-items-center mb-3">
                <component-shimmer class="rounded-circle d-block me-3" height="64px" width="64px"/>
                <div>
                    <component-shimmer class="mb-1"/>
                    <component-shimmer width="241.8px"/>
                </div>
            </div>

            <div class="d-flex mb-3">
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                </div>
            </div>
        </template>

    </component-slide-in>
</template>

<script>
import ComponentSlideIn from "./ui/ComponentSlideIn";
import ComponentAvatar from "./ui/ComponentAvatar";
import ComponentSlideInTitle from "./ui/ComponentSlideInTitle";
import {countries} from "countries-list";
import ComponentShimmer from "./ComponentShimmer";
import {maxLength, required} from "vuelidate/lib/validators";
import getCookie from "../lib/get-cookie";

export default {
    name: "ComponentUpdateUser",
    components: {ComponentShimmer, ComponentSlideInTitle, ComponentAvatar, ComponentSlideIn},
    computed: {
        v() {
            return this.$v;
        },
        loading() {
            return {
                USER_GET_PROFILE: this.$store.state.loading.USER_GET_PROFILE,
                USER_UPDATE_PROFILE: this.$store.state.loading.USER_UPDATE_PROFILE
            }
        },
        profile() {
            return this.$store.state.USER.profile;
        },
        salutation: {
            get() {
                return this.edit.flag ? this.edit.salutation : this.profile.salutation;
            },
            set(salutation) {
                this.edit.salutation = salutation;
            }
        },
        firstName: {
            get() {
                return this.edit.flag ? this.edit.firstName : this.profile.firstName;
            },
            set(firstName) {
                this.edit.firstName = firstName;
            }
        },
        lastName: {
            get() {
                return this.edit.flag ? this.edit.lastName : this.profile.lastName;
            },
            set(lastName) {
                this.edit.lastName = lastName;
            }
        },
        birthday: {
            get() {
                return this.edit.flag ? this.edit.birthday : this.profile.birthday;
            },
            set(birthday) {
                this.edit.birthday = birthday;
            }
        },
        company: {
            get() {
                return this.edit.flag ? this.edit.company : this.profile.company;
            },
            set(company) {
                this.edit.company = company;
            }
        },
        vatId: {
            get() {
                return this.edit.flag ? this.edit.vatId : this.profile.vatId;
            },
            set(vatId) {
                this.edit.vatId = vatId;
            }
        },
        street: {
            get() {
                return this.edit.flag ? this.edit.street : this.profile.street;
            },
            set(street) {
                this.edit.street = street;
            }
        },
        postcode: {
            get() {
                return this.edit.flag ? this.edit.postcode : this.profile.postcode;
            },
            set(postcode) {
                this.edit.postcode = postcode;
            }
        },
        city: {
            get() {
                return this.edit.flag ? this.edit.city : this.profile.city;
            },
            set(city) {
                this.edit.city = city;
            }
        },
        state: {
            get() {
                return this.edit.flag ? this.edit.state : this.profile.state;
            },
            set(state) {
                this.edit.state = state;
            }
        },
        country: {
            get() {
                return this.edit.flag ? this.edit.country : this.profile.country;
            },
            set(country) {
                this.edit.country = country;
            }
        },
        accessKey() {
            const user = getCookie('user');
            return user.key;
        },
        accessSecret() {
            if (this.showAccessKey === false) {
                return '********';
            }

            const user = getCookie('user');
            return user.secret;
        }
    },
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            countries,
            showAccessKey: false,
            edit: {
                flag: false,
                salutation: '',
                firstName: '',
                lastName: '',
                birthday: '',
                company: '',
                vatId: '',
                street: '',
                postcode: '',
                city: '',
                state: '',
                country: ''
            }
        }
    },
    methods: {
        close() {
            this.$store.commit('backdrop/hide');
            this.$emit('close');
        },
        onUpdateUser() {
            const payload = {
                email: this.profile.email,
                vatId: this.profile.vatId,
                salutation: this.salutation !== '' ? this.salutation : null,
                firstName: this.firstName !== '' ? this.firstName : null,
                lastName: this.lastName !== '' ? this.lastName : null,
                company: this.company !== '' ? this.company : null,
                street: this.street !== '' ? this.street : null,
                postcode: this.postcode !== '' ? this.postcode : null,
                city: this.city !== '' ? this.city : null,
                state: this.state !== '' ? this.state : null,
                country: this.country !== '' ? this.country : null,
                birthday: this.birthday !== '' ? this.birthday : null,
                billingAddress: {
                    salutation: this.profile.billingAddress.salutation,
                    firstName: this.profile.billingAddress.firstName,
                    lastName: this.profile.billingAddress.lastName,
                    company: this.profile.billingAddress.company,
                    street: this.profile.billingAddress.street,
                    postcode: this.profile.billingAddress.postcode,
                    city: this.profile.billingAddress.city,
                    state: this.profile.billingAddress.state,
                    country: this.profile.billingAddress.country,
                },
                shippingAddress: {
                    salutation: this.profile.shippingAddress.salutation,
                    firstName: this.profile.shippingAddress.firstName,
                    lastName: this.profile.shippingAddress.lastName,
                    company: this.profile.shippingAddress.company,
                    street: this.profile.shippingAddress.street,
                    postcode: this.profile.shippingAddress.postcode,
                    city: this.profile.shippingAddress.city,
                    state: this.profile.shippingAddress.state,
                    country: this.profile.shippingAddress.country,
                }
            }

            this.$store.dispatch('USER/UPDATE_PROFILE', payload)
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.success'),
                        message: this.$tc('toast.success.user-updated')
                    });
                    this.edit.flag = false
                    this.$gtag.event('USER_UPDATE_PROFILE');
                })
                .catch(() => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: this.$tc('toast.danger.user-updated')
                    });
                    this.edit.flag = false
                });
        },
        onClickEdit() {
            this.edit.salutation = this.profile.salutation;
            this.edit.firstName = this.profile.firstName;
            this.edit.lastName = this.profile.lastName;
            this.edit.birthday = this.profile.birthday;
            this.edit.company = this.profile.company;
            this.edit.street = this.profile.street;
            this.edit.postcode = this.profile.postcode;
            this.edit.city = this.profile.city;
            this.edit.state = this.profile.state;
            this.edit.country = this.profile.country;
            this.edit.flag = true;
        },
        closeOnEscape(e) {
            if (e.key === 'Escape' && this.active === true) {
                this.close();
            }
        }
    },
    validations: {
        salutation: {
            required
        },
        firstName: {
            required,
            maxLength: maxLength(255)
        },
        lastName: {
            required,
            maxLength: maxLength(255)
        },
        birthday: {},
        company: {
            maxLength: maxLength(255)
        },
        vatId: {
            maxLength: maxLength(255)
        },
        street: {
            required,
            maxLength: maxLength(255)
        },
        postcode: {
            required,
            maxLength: maxLength(50)
        },
        city: {
            required,
            maxLength: maxLength(255)
        },
        state: {
            maxLength: maxLength(255)
        },
        country: {
            required
        },
    },
    watch: {
        active(newValue) {
            if (newValue === true) {
                window.addEventListener('keydown', this.closeOnEscape);
            } else {
                window.removeEventListener('keydown', this.closeOnEscape);
            }
        }
    }
}
</script>

<style scoped>

</style>