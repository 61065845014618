<template>
    <layout-full-width :title="$tc('user', 2)">
        <component-actions :action-filters="[]"
                           :itemsPerPage="itemsPerPage"
                           :page="page"
                           :primary-actions="primaryActions"
                           :total="1"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @refresh="listUsers"
                           @search="onSearch"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table :class="{'table-hover': !loading.USER_GET_PROFILE}" class="table">
                        <thead>
                        <tr>
                            <th scope="col">
                                <button class="btn btn-checkbox" @click="toggleCheckbox">
                                    <font-awesome-icon
                                        :class="getSelectAllStatus() ? 'text-primary' : ''"
                                        :icon="getSelectAllStatus() ? ['fas', 'check-square'] : ['far', 'square']"/>
                                </button>
                            </th>
                            <th scope="col">{{ $tc('username', 1) }}</th>
                            <th scope="col">{{ $tc('first-name', 1) }}</th>
                            <th scope="col">{{ $tc('last-name', 1) }}</th>
                            <th scope="col">{{ $tc('type', 1) }}</th>
                            <th scope="col">{{ $tc('status', 1) }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-cloak v-if="!loading.USER_GET_PROFILE && profile !== null">
                            <tr class="pointer" @click="onClickUser($event)">
                                <td class="align-middle">
                                    <button class="btn btn-checkbox" @click="toggleCheckbox">
                                        <font-awesome-icon
                                            :class="profile.selected ? 'text-primary' : ''"
                                            :icon="profile.selected ? ['fas', 'check-square'] : ['far', 'square']"
                                            class="pe-none"/>
                                    </button>
                                </td>
                                <td>{{ profile.username }}</td>
                                <td>{{ profile.firstName }}</td>
                                <td>{{ profile.lastName }}</td>
                                <td>
                                    <span class="badge bg-secondary">{{ $tc('user', 1) }}</span>
                                </td>
                                <td>
                                    <span class="badge bg-info">{{ $tc('active', 1) }}</span>
                                </td>
                            </tr>
                        </template>
                        <template v-cloak v-if="!loading.USER_GET_PROFILE && profile === null">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('pages.users.no-users-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.USER_GET_PROFILE">
                            <tr v-for="n in itemsPerPage" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer height="14px" style="margin: 4px 0;" width="14px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="160px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="100px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="100px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="35px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="42px"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <component-update-user :active="update" @close="update = false"/>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "../../layouts/LayoutFullWidth";
import ComponentActions from '../../components/ui/ComponentActions.vue';
import ComponentUpdateUser from "../../components/ComponentUpdateUser";
import shouldOpenSlideIn from "../../lib/should-open-slide-in";
import ComponentShimmer from "../../components/ComponentShimmer";

export default {
    name: "PageMerchants",
    components: {
        ComponentShimmer,
        ComponentUpdateUser,
        LayoutFullWidth,
        ComponentActions
    },
    computed: {
        loading() {
            return {
                USER_GET_PROFILE: this.$store.state.loading.USER_GET_PROFILE
            }
        },
        profile() {
            return this.$store.state.USER.profile
        }
    },
    data() {
        return {
            page: 1,
            itemsPerPage: 10,
            primaryActions: [
                {
                    text: this.$t('actions.new'),
                    icon: 'plus',
                    type: 'modal',
                    disabled: true,
                    target: ''
                }
            ],
            update: false
        }
    },
    methods: {
        toggleCheckbox() {
            this.profile.selected = !this.profile.selected;
            this.$forceUpdate();
        },
        getSelectAllStatus() {
            return this.profile.selected === true;
        },
        listUsers() {
            this.$store.dispatch('USER/GET_PROFILE');
        },
        onChangePage(page) {
            this.page = page;
            this.listUsers();
        },
        onSearch() {
            console.log('onSearch');
        },
        onChangeItemsPerPage(itemsPerPage) {
            this.itemsPerPage = itemsPerPage;
            this.listUsers();
        },
        onApplyFilters() {
            console.log('onApplyFilters');
        },
        onClickUser(event) {
            if (!shouldOpenSlideIn(event.target)) {
                return;
            }

            this.$store.commit('backdrop/show');
            this.update = true;
        },
    },
    beforeMount() {
        this.listUsers()
    }
}
</script>

<style lang="scss" scoped>
</style>
